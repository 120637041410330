@charset 'UTF-8';

/*******************************
* フォント
********************************/
//$sans: 'NotoSansCJKjp', sans-serif;
//$serif: 'Noto Serif JP', serif;
$serif:'Noto Serif JP', serif;
$sans:'Noto Sans JP', sans-serif;
$fa: 'Font Awesome 5 Free';
$os: 'Open Sans', 'Noto Serif JP', serif;
$dm: 'DM Serif Display', serif;

/*******************************
* カラー
********************************/
$white: #fff;
$black: #333;
$text-color: #333333;
$border: #ccd9e3;

$main-color: #004098;
$sub-color: #00b9ef;
$accent-color: #37b0a9;

$bg-blue: #d3edfb;
$bg-gray: #eaeef1;
$bg-light: #eaf6fd;
$bg-gray2: #f5f3f2;
$bg-gray3: #ccc;

$blue1: #d3edfb;
$blue2: #eaf6fd;
$blue3: #54c3f1;


/*******************************
* Mixin
********************************/
@mixin gd-pink( $deg: 90deg ) {
  background: linear-gradient( #{$deg}, #fdacc6 0%, #f7cccd 100%);
}
@mixin gd-blue( $deg: 90deg ) {
  background: linear-gradient( #{$deg}, #ccd9e3 0%, #c9b8e4 100%);
}
@mixin c-pink {
  position: relative;
  padding: 0 0 0 21px;
  line-height: 1.8;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 7px;
    width: 15px;
    height: 15px;
    background: $pink3;
    border-radius: 10px;
  }
}

@mixin obf {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

/*******************************
* イージング
********************************/
$linear: cubic-bezier(0.0, 0.0, 1.0, 1.0);

$easeInSine: cubic-bezier(.47, 0, .745, .715);
$easeOutSine: cubic-bezier(.39, .575, .565, 1);
$easeInOutSine: cubic-bezier(.445, .05, .55, .95);

$easeInQuad: cubic-bezier(.55, .085, .68, .53);
$easeOutQuad: cubic-bezier(.25, .46, .45, .94);
$easeInOutQuad: cubic-bezier(.455, .03, .515, .955);

$easeInCubic: cubic-bezier(.55, .055, .675, .19);
$easeOutCubic: cubic-bezier(.215, .61, .355, 1);
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1);

$easeInQuart: cubic-bezier(.895, .03, .685, .22);
$easeOutQuart: cubic-bezier(.165, .84, .44, 1);
$easeInOutQuart: cubic-bezier(.77, 0, .175, 1);

$easeInQuint: cubic-bezier(.755, .05, .855, .06);
$easeOutQuint: cubic-bezier(.23, 1, .32, 1);
$easeInOutQuint: cubic-bezier(.86, 0, .07, 1);

$easeInExpo: cubic-bezier(.95, .05, .795, .035);
$easeOutExpo: cubic-bezier(.19, 1, .22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

$easeInCirc: cubic-bezier(.6, .04, .98, .335);
$easeOutCirc: cubic-bezier(.075, .82, .165, 1);
$easeInOutCirc: cubic-bezier(.785, .135, .15, .86);

$easeInBack: cubic-bezier(.6, -0.28, .735, .045);
$easeOutBack: cubic-bezier(.175, .885, .32, 1.275);
$easeInOutBack: cubic-bezier(.68, -0.55, .265, 1.55);
